<template lang="pug">
  v-dialog(
    v-model="openDialog"
    eager
    width="800"
  )
    v-card
      v-toolbar(
        color="yellow darken-3"
        flat
        dark
        dense
      )
        span Edit
      v-container
        v-row
          v-col(cols="4")
            v-autocomplete(
              :items="stockList"
              :loading="!stockList.length"
              item-value="id"
              label="Stock"
              item-text="description"
              v-model="stockId"
              :filter="filterStockAutocomplete"
              :error-messages="orderDetailUpdatePutErrors.stock_id"
            )
              template(v-slot:selection="{ item }")
                span.primary--text.mr-2 {{ item.id }}
              template(v-slot:item="{ item }")
                span.primary--text.mr-2 {{ item.id }}
                small.blue-grey--text {{ item.description }}
          v-col(cols="4")
            v-autocomplete(
              :items="statuses"
              :loading="!statuses.length"
              item-value="id"
              item-text="name"
              label="Status"
              v-model="orderDetailStatusId"
              :error-messages="orderDetailUpdatePutErrors.order_detail_status_id"
            )
          v-col(cols="4")
            v-autocomplete(
              label="Unit"
              :items="units"
              v-model="unit"
              :error-messages="orderDetailUpdatePutErrors.unit"
            )
          v-col(cols="4")
            v-text-field(
              label="Quantity"
              type="number"
              v-model.number="quantity"
              :error-messages="orderDetailUpdatePutErrors.quantity"
            )
          v-col(cols="4")
            v-text-field(
              label="SRP"
              v-model="unitPrice"
              :error-messages="orderDetailUpdatePutErrors.unit_price"
            )
          v-col(cols="4")
            v-text-field(
              label="Discount"
              v-model="discount"
              :error-messages="orderDetailUpdatePutErrors.quantity"
            )
          v-col(cols="4")
            v-text-field(
              label="Date Allocated"
              type="date"
              v-model="dateAllocated"
              :error-messages="orderDetailUpdatePutErrors.date_allocated"
            )
          v-col(cols="3")
            v-btn(
              color="yellow darken-2"
              dense
              @click="update"
              block
              :loading="orderDetailUpdatePutting"
              dark
            )
              span update
          v-col(cols="3")
            v-btn(
              color="red"
              dense
              @click="openDialog = false"
              block
              dark
            )
              span cancel
</template>
<script>
import orderDetailRepository from '@/repositories/order-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [orderDetailUpdateVars, orderDetailUpdateVarNames] = requestVars({ identifier: 'order-detail-update', request: 'put', hasData: false })

const orderDetailUpdateHandler = new VueRequestHandler(null, orderDetailUpdateVarNames)

const inputVars = () => ({
  id: null,
  orderId: null,
  stockId: null,
  unit: null,
  quantity: null,
  unitPrice: null,
  discount: null,
  orderDetailStatusId: null,
  dateAllocated: null,
})

export default {
  name: 'EditOrderDetail',
  props: ['value', 'orderData', 'excludedStocks', 'stocks', 'statuses'],
  data () {
    return {
      editDefaults: {},
      ...orderDetailUpdateVars,
      ...inputVars(),
    }
  },
  computed: {
    compExcludedStocks () {
      return this.excludedStocks.filter(item => item !== this.editDefaults.stockId)
    },
    openDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
    units () {
      const stock = this.selectedStock || {}
      if (stock.stock_category_id === 'EG01') {
        return ['egg']
      }
      return [
        'kg',
        'bag',
        'lot',
      ]
    },
    stockData () {
      return this.stockList.find(item => item.id === this.stockId) || {}
    },
    stockList () {
      return this.stocks
        .filter(item => {
          if (this.compExcludedStocks.includes(item.id)) return false
          return this.orderData.revenueSourceId === item.revenue_source.id
        })
    },
  },
  watch: {
    unit () {
      this.fillInUnitPrice()
    },
    stockData () {
      this.fillInUnitPrice()
    },
  },
  methods: {
    filterStockAutocomplete (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.id) || regExp.test(item.description)
    },
    setInputVars (data) {
      this.editDefaults = data
      Object.keys(inputVars())
        .forEach(key => {
          this[key] = data[key]
        })
    },
    fillInUnitPrice () {
      if (!this.stockId || !this.unit) return
      if (this.stockData.stock_category_id === 'EG01') {
        this.balutUnitPrice(this.stockData)
        return
      }
      this.defaultUnitPrice()
    },
    balutUnitPrice () {
      this.unitPrice = this.stockData.default_detail.price
    },
    defaultUnitPrice () {
      const { unit } = this
      if (unit === 'bag') {
        this.unitPrice = this.stockData.feed_detail.resell_per_bag || null
        return
      }
      this.unitPrice = this.stockData.feed_detail.resell_per_kilo || null
    },
    getInputData () {
      return Object.keys(inputVars())
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
    update () {
      const handler = orderDetailUpdateHandler
      const repository = orderDetailRepository.update
      const { id, ...data } = this.getInputData()
      handler.setVue(this)
      handler.execute(repository, [id, data], this.reset)
    },
    reset () {
      const defaults = inputVars()
      this.openDialog = false
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
    },
  },
}
</script>